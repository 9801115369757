import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import TargetsTable from './TargetsTable';
import SensorStatusIndicator from './SensorStatusIndicator';
import AudioStream from './AudioStream';
import {isGAFlight} from './utils';

const containerStyle = {
  width: '600px',
  height: '800px'
};

function loadSensors() {
  return new Promise((resolve) => {
    fetch('https://api.adsb.planeenglish.net/sensors')
        .then(res => res.json())
        .then((data) => {
          resolve(data['body']);
        })
        .catch(console.log)
  })
}

function loadTargets(sensor_id, view_all) {
  return new Promise((resolve) => {
    fetch('https://api.adsb.planeenglish.net/targets')
        .then(res => res.json())
        .then((data) => {
          if(view_all) {
            resolve(data['body']);
          }
          else {
            resolve(data['body'].filter(target => target.sensor_id === sensor_id));
          }
        })
        .catch(console.log)
  })
}

function addMarkers(targets, selectedTarget, targetClickHandler, targetPositionCountCallback) {
  let markers = [];
  let numTargetsWithPositions = 0;

  for(const target of targets) {
    let latitude = parseFloat(target.latitude)
    let longitude = parseFloat(target.longitude)
    let strokeColor = 'black';
    let strokeWeight = 1;

    if(target.call_sign && !isNaN(latitude) && !isNaN(longitude)) {
      numTargetsWithPositions++;
      let color = '#010002';
      if(target.altitude > 0) {
        if(target.altitude <= 1000) {
          color = '#c62828';
        }
        else if(target.altitude > 1000 && target.altitude <= 5000) {
          color = '#ffa000';
        }
        else if(target.altitude > 5000 && target.altitude <= 10000) {
          color = '#558b2f';
        }
        else if(target.altitude > 10000 && target.altitude <= 25000) {
          color = '#1565c0';
        }
        else if(target.altitude > 25000) {
          color = '#ab47bc';
        }
      }

      let path = 'M482,363.333c2.667,0.666,5,0.332,7-1c2-1.334,3-3.334,3-6v-36c0-6.668-3-12-9-16l-187-111 c-6-3.334-9-8.668-9-16v-125c0-6.667-1.333-13-4-19c-7.333-18.667-17.667-29-31-31c-2-0.667-4-1-6-1s-4.333,0.333-7,1 c-0.667,0-1.5,0.167-2.5,0.5s-2.167,0.833-3.5,1.5c-4.667,1.333-9,4.333-13,9s-7,9-9,13l-3,7c-2,6-3,12.333-3,19v125 c0,7.333-3,12.667-9,16l-187,111c-6,4-9,9.332-9,16v36c0,2.666,1,4.666,3,6c2,1.332,4.333,1.666,7,1l185-60 c2.667-1.334,5-1.168,7,0.5c2,1.666,3,3.832,3,6.5v97c0,7.334-2.667,13-8,17l-25,18c-5.333,4-8,9.666-8,17v24c0,2.666,1,4.666,3,6 s4.333,1.666,7,1l62-18c6.667-2,13.333-2,20,0l62,18c2.667,0.666,5,0.334,7-1s3-3.334,3-6v-24c0-7.334-2.667-13-8-17l-25-18 c-5.333-4-8-9.666-8-17v-97c0-3.334,1-5.668,3-7c2-1.334,4.333-1.334,7,0L482,363.333z';
      let scale = 0.05;
      
      if(target.call_sign && isGAFlight(target.call_sign)) {
        path = 'M3733 5258 c-6 -7 -20 -43 -32 -79 -18 -55 -27 -69 -52 -79 -35 -15 -87 -72 -93 -103 -13 -61 -46 -363 -46 -421 l0 -66 -381 0 c-327 0 -456 -5 -922 -35 -464 -31 -544 -38 -555 -52 -17 -24 -17 -442 1 -467 10 -14 107 -30 552 -92 l540 -74 387 0 c214 0 388 -4 388 -8 0 -5 25 -226 55 -492 30 -266 53 -485 50 -488 -3 -3 -127 -29 -277 -58 -157 -31 -275 -59 -280 -66 -13 -22 -10 -324 5 -336 6 -6 134 -29 282 -51 149 -23 282 -44 297 -47 21 -4 35 2 67 32 l41 36 41 -36 c32 -29 46 -36 67 -32 15 3 148 24 297 47 148 22 275 45 282 51 15 12 19 314 5 336 -8 12 -188 51 -447 97 -55 10 -103 21 -107 24 -4 4 17 222 47 486 30 264 55 486 55 493 0 9 85 12 393 12 l392 0 535 74 c443 62 536 78 547 92 18 25 19 443 1 467 -11 14 -87 21 -550 52 -463 30 -589 35 -922 35 l-386 0 0 65 c0 60 -33 369 -45 422 -7 32 -59 88 -95 103 -24 10 -32 23 -45 69 -9 31 -23 66 -31 79 -16 24 -46 29 -61 10z m105 -602 c46 -24 74 -61 70 -92 l-3 -29 -145 0 -145 0 -3 28 c-9 78 139 138 226 93z m20 -878 c21 -21 14 -73 -14 -99 -21 -20 -36 -24 -84 -24 -48 0 -63 4 -84 24 -28 26 -35 78 -14 99 16 16 180 16 196 0z';
        scale = 0.007;
      }

      markers.push(
        <Marker 
          key={target.aid} 
          position={{ lat: latitude, lng: longitude }}
          onClick={() => {targetClickHandler(target)} }
          icon={
            {
              path: path,
              fillColor: color,
              fillOpacity: 0.8,
              scale: scale,
              strokeColor: strokeColor,
              strokeWeight: strokeWeight,
              rotation: target.track ? parseInt(target.track) : 0,
            }
          }
          
        >
        </Marker>
      )
    }
  }
  targetPositionCountCallback(numTargetsWithPositions);
  return markers;
}

function App() {
  //Sensor
  const [sensorParams, setSensorParams] = useState({});
  const [mapCenter, setMapCenter] = useState({
    lat: 47.45,
    lng: -122.31
  });

  //Targets
  const [targets, setTargets] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [numTargetsWithPositions, setNumTargetsWithPositions] = useState(0);

  //Highlight table row of clicked target on map
  const targetClickHandler = (target) => {
    setSelectedTarget(target);
    if(target && target.aid) {
      document.getElementById(target.aid).className = 'table-success';
    }
  };

  //Initialize sensor and load initial params and targets
  React.useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const id = queryParameters.get("sensor");
    const viewAll = (queryParameters.has("view") && queryParameters.get("view") && queryParameters.get("view") === 'all');
    
    loadSensors().then(sensors => {
      for(const sensor of sensors) {
        if(sensor.id === id) {
          setSensorParams(sensor);
          setMapCenter({
            lat: parseFloat(sensor.latitude),
            lng: parseFloat(sensor.longitude)
          });

          loadTargets(sensor.id, viewAll).then(targets => {
            setMarkers(addMarkers(targets, selectedTarget, targetClickHandler, setNumTargetsWithPositions));
            setTargets(targets);
          });

          //Update targets interval
          const interval = setInterval(() => {
            loadTargets(sensor.id, viewAll).then(targets => {
              setMarkers([]);
              setMarkers(addMarkers(targets, selectedTarget, targetClickHandler, setNumTargetsWithPositions));
              setTargets(targets);
            });
          }, 5000);

          return () => {
            clearInterval(interval);
          };
        }
      }
    })
   }, [])

   //Listen for keypresses 
   React.useEffect(() => {
        const listener = e => {
          if (e.key === "Escape") {
            targetClickHandler(null);
          }
      };   window.addEventListener("keydown", listener);   return () => {
          window.removeEventListener("keydown", listener);
      };
   }, [])

  return (
    <div class="container text-center">
      <div class="row">
        <div class="col col-md-auto">
          <LoadScript
            googleMapsApiKey="AIzaSyDbiuXzg_5XXiInnBfGPhSDP7xzwNnW9Lo"
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={mapCenter}
              zoom={12}
            >
              { markers }
              <></>
            </GoogleMap>
          </LoadScript>
        </div>
        <div class="col col-md-auto">
        <div class="alert alert-secondary" role="alert" align="left">
          <small>Sensor ID: <b>{sensorParams['id']}</b></small>
          <br/>
          <small>Site Name: <b>{sensorParams['site_name']}</b></small>
          <br/>
          <small>Status: <SensorStatusIndicator status={sensorParams['status']} /></small>
          <br/>
          <small>Total Aircraft: <b>{targets.length}</b></small>
          <br/>
          <small>With Positions: <b>{numTargetsWithPositions}</b></small>
          <br/>
          <hr/>
          <small>Monitored VHF Frequencies: <b>{sensorParams && sensorParams['vhf_monitored_frequencies'] ? sensorParams['vhf_monitored_frequencies'].join(', ') : '--'}</b></small>
          <br/>
          <AudioStream site_name={sensorParams['site_name']}/>
        </div>
          <div class="alert alert-secondary" role="alert">
            <table class="table table-sm table-striped">
              <thead>
                <tr>
                  <th scope="col"><small>Ident</small></th>
                  <th scope="col"><small>Squawk</small></th>
                  <th scope="col"><small>Altitude</small></th>
                  <th scope="col"><small>Speed</small></th>
                  <th scope="col"><small>Vertical Rate</small></th>
                  <th scope="col"><small>Track</small></th>
                </tr>
              </thead>
              <tbody id="targets-table">
                <TargetsTable 
                  targets={targets}
                  selectedTarget={selectedTarget}
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App;
