import React, { useState } from 'react';

export default function AudioStream(props) {
    if(props.site_name) {
        return (
            <audio controls autoPlay>
                <source src={"http://icecast.planeenglish.net:8000/" + props.site_name + ".mp3"} type="audio/mpeg"/>
            </audio>
        );
    }
    return '';
}