export function isGAFlight(call_sign) {
    if(!call_sign) {
        return true;
    }
    
    const regex = /[A-Z]{3}.+/g; //E.G. DALXXXX, EJAXXXX, SKWXXXX, etc. 
    if(!call_sign.match(regex)) { //GA flight, probably. Use different icon
        return true;
    }
    return false;
}