import React, { useState } from 'react';

export default function TargetsTable(props) {
    var target_rows = [];
    for(const target of props.targets) {
        /*ID, Squawk, Altitude, Speed, Vertical Rate, Track*/
        let id = '';
        if(target.call_sign) {
            id = <a href={"https://www.flightaware.com/live/flight/" + target.call_sign} target="_blank"><b>{target.call_sign}</b></a>;
        }
        else {
            id = target.aid;
            if(id.includes('#')) {
                id = <font color="#989898">{id.substring(id.indexOf('#')+1)}</font>;
            }
        }

        let squawk = target.squawk_code ? target.squawk_code : '';
        let altitude = target.altitude ? target.altitude + " ft" : '';
        let speed = target.ground_speed ? target.ground_speed + " kts" : '';
        let vertical_rate  = target.vertical_rate ? target.vertical_rate + " fpm" : '';
        let track = target.track ? target.track + '°' : '';
        let selectedStyle = '';
        if(props.selectedTarget && props.selectedTarget.aid === target.aid) {
            selectedStyle = 'table-success';
        }
        target_rows.push(
            <tr id={target.aid} class={selectedStyle}>
                <td><small>{id}</small></td>
                <td><small>{squawk}</small></td>
                <td><small>{altitude}</small></td>
                <td><small>{speed}</small></td>
                <td><small>{vertical_rate}</small></td>
                <td><small>{track}</small></td>
            </tr>
        );
    }
    return target_rows;
}